<template>
  <section>
    <div class="content-header">
      <h2>Resilience within Indigenous communities</h2>
    </div>
    <div class="content-wrapper">
      <p>Indigenous scholars and community-based researchers have been studying concepts of resilience within Indigenous communities. </p>
      <p>Experts have identified cultural resilience factors like connection to language, land, and cultural identity as key strengths to supporting community resilience among Indigenous individuals and communities. </p>
      <p>Learn more in the article, <a href="https://journals.sagepub.com/doi/abs/10.1177/070674371105600203" target="_blank">Rethinking Resilience from Indigenous Perspectives</a> from Kirmayer, Dandaneau, & Marshall (2011).</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
